import { cva, type VariantProps } from 'class-variance-authority';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import clsx from 'clsx';

const buttonVariants = cva('rounded-[50px] border-none text-lg transition-all duration-300 ease-in-out', {
    variants: {
        variant: {
            primary: 'bg-[#FFD60A] text-black font-semibold hover:bg-yellow-400 hover:bg-opacity-90',
            'primary-nav': 'bg-[#FFD60A] text-black font-semibold hover:bg-yellow-400 hover:bg-opacity-90',
            outline: 'border border-white text-white hover:bg-white hover:text-black'
        },
        size: {
            default: 'px-6 py-4',
            medium: 'px-6 py-3',
            sm: 'px-4 py-2',
            lg: 'px-8 py-5'
        }
    },
    defaultVariants: {
        variant: 'primary',
        size: 'default'
    }
});

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, ...props }, ref) => {
    return <button className={clsx(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});

Button.displayName = 'Button';

export { Button, buttonVariants };
export type { ButtonProps };
