import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { theme } from 'theme';
import { MobileNavDrawer } from 'components/MobileNavDrawer';
import { useDispatchUi, useUi } from 'context/ui';
import Hamburger from 'hamburger-react';
import { debounce } from 'utils/debounce';
import LogoImage from '@/components/Header/LogoImage';

export const NewMobileNav = ({ currentPath, stepUpNav }) => {
    // const [scrollingNav, setScrollingNav] = useState(false);
    const ui: any = useUi();
    const dispatch: any = useDispatchUi();

    const logoURL = '/assets/logos/Muzology-logo-with-heart-tm.svg';
    // const logoURL = '/assets/muzology-logo-tm.png';
    // const logoURL = '/assets/muzology-logo-9-23.png';

    // const handleScroll = debounce(() => {
    //     setScrollingNav(true);
    //     // // find current scroll position
    //     const currentScrollPos = window.pageYOffset;
    //
    //     if (currentScrollPos > 1) {
    //         setScrollingNav(true);
    //     } else {
    //         setScrollingNav(false);
    //     }
    // }, 12);

    /*
    useEffect(() => {
        // Ensure we are in client, not build
        if (!process.browser) {
            return null;
        }

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);
    */

    const clickableLogo = !stepUpNav;

    return (
        <nav data-comp={NewMobileNav.displayName}>
            {ui?.mobileNavDrawerVisible && <MobileNavDrawer currentPath={currentPath} />}
            <div
                className='flex justify-center items-center cursor-pointer'
                // style={{ cursor: 'pointer', background: 'green' }}
            >
                <LogoImage id='logo' url={logoURL} clickable={clickableLogo} />
                {/*
                <Link href='/'>
                    <img
                        id='logo'
                        // height='76px'
                        // width='auto'
                        src='/assets/muzology-logo@161.png'
                        alt='Muzology Logo'
                    />
                </Link>
                */}
            </div>

            <div onClick={ui?.mobileNavDrawerVisible ? () => dispatch({ type: 'CLOSE_NAV_DRAWER' }) : () => dispatch({ type: 'OPEN_NAV_DRAWER' })}>
                <Hamburger label='Main Menu' color='#fff' toggled={ui?.mobileNavDrawerVisible} size={24} />
            </div>
            <style jsx>{`
                #logo {
                    height: 66px;
                    width: auto;

                    @media (max-width: 480px) {
                        height: 52px;
                    }
                }

                nav {
                    box-sizing: border-box;

                    z-index: 90;

                    position: fixed;
                    top: 0;
                    padding: 0 32px;
                    height: 76px;
                    width: 100%;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    //align-content: center;

                    //background-color:#271556;
                    background-color: #271556;
                    box-shadow: 0 10px 30px 0;
                }

                @media (max-width: 480px) {
                    nav {
                        padding: 0px 20px;
                    }

                    #logo {
                        //width: 108px;
                    }
                }
            `}</style>
        </nav>
    );
};

NewMobileNav.displayName = 'NewMobileNav';
