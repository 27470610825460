import React, { useEffect, useState } from 'react';
import { debounce } from '@/utils/debounce';
import LogoImage from '@/components/Header/LogoImage';
import Link from 'next/link';
import NavMenu from '@/components/Header/NavMenu';
import { LoginButton, NavbarButton } from '@/components/Views/Buttons';
import { MUZOLOGY_APP, MUZOLOGY_SIGNUP_URL } from '@/lib/defaults';
import { theme } from '@/theme/index';
import cx from 'classnames';
import { Button } from '@/components/HomePageV2/Components/Button';
import { useRouter } from 'next/router';

export const NewNavbar = ({
    currentPath,
    showClassCodeInput,
    showLoginAndSignup,
    signupLink,
    signupLinkText,
    hideNavigationMenu,
    stepUpNav,
    site,
    timesTables = false
}) => {
    const [scrollingNav, setScrollingNav] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (!process.browser) {
            return null;
        }
        const handleScroll = debounce(() => {
            setScrollingNav(true);
            // // find current scroll position
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > 1) {
                setScrollingNav(true);
            } else {
                setScrollingNav(false);
            }
        }, 10);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const clickableLogo = !stepUpNav;
    // const logoURL = '/assets/muzology-logo-tm.png';
    // const logoURL = '/assets/muzology-logo-9-23.png';
    // const logoURL = '/assets/logo2.svg';
    const logoURL = '/assets/logos/Muzology-logo-with-heart-tm.svg';

    // if (site && site.logo) {
    //     logoURL = site.logo.url;
    // }

    const handleStartFreeTrial = () => {
        router.push(MUZOLOGY_SIGNUP_URL);
    };

    const timesTablesRoute = currentPath === '/timestables';
    return (
        <nav
            className={cx(
                'fixed top-0 left-0 right-0 z-50 w-full transition-all duration-300 ease-in-out',
                {
                    'bg-transparent': !scrollingNav,
                    'bg-[#271556] shadow-lg translate-y-0': scrollingNav
                },
                'animate-slideDown'
            )}
            data-comp={NewNavbar.displayName}
        >
            <div id='nav-content'>
                <div id='nav-left'>
                    <LogoImage url={logoURL} clickable={clickableLogo} />
                </div>

                <div id='nav-right' className='flex justify-end gap-4 xl:gap-9 '>
                    <div className='flex justify-end items-center gap-3 xl:gap-6'>
                        <Link href={MUZOLOGY_APP}>
                            <button className='px-10 py-3 bg-transparent text-white border-white border-[1.5px] border-solid font-semibold rounded-[50px] text-base transition-all duration-300 ease-in-out hover:bg-white hover:text-[#271556] hover:scale-105 hover:shadow-lg'>
                                Log In
                            </button>
                        </Link>

                        {timesTables && (
                            <Link href='/timestables/checkout'>
                                <Button variant='primary' className=' py-2.5 max-h-[58px]'>
                                    Get Started
                                </Button>
                            </Link>
                        )}

                        {!timesTables && (
                            <Button variant='primary' size='medium' className='' onClick={handleStartFreeTrial}>
                                Start Free Trial
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <style jsx>{`
                nav {
                    font-family: ${theme.font.primary};
                    position: fixed;
                    display: flex;
                    align-items: center;
                    height: 72px;

                    width: 100%;
                    top: 0;
                    z-index: 90;
                    padding: 0px 24px 0px 24px;
                    transition: all 100ms linear;
                    background-color: 'none';
                    box-shadow: ${scrollingNav ? 'rgb(25 45 100 / 8%) 0px 10px 30px 0px;' : 'none'};
                    @media print {
                        background-color: #fff;
                        box-shadow: none;
                    }

                    @media (max-width: 1280px) {
                        padding: 0 10px 0 10px;
                    }
                }

                #nav-content {
                    margin: 0 auto;
                    height: 100%;
                    width: 100%;
                    max-width: ${stepUpNav ? '' : ''};
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    position: relative;
                }

                #nav-left {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                }

                #nav-center {
                    display: flex;
                    justify-content: center;
                    //margin: 0 44px 0 auto;
                    @media (max-width: 1280px) {
                        //margin: 0 0 0 auto;
                    }
                    @media print {
                        display: none;
                    }
                }

                #nav-right {
                    //background: blue;
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    flex-grow: 1;
                    @media print {
                        display: none;
                    }
                }

                #logo {
                    cursor: pointer;
                    //width: 171px;
                }

                #logo-stepup {
                    //width: 171px;
                }

                #login-btn {
                    cursor: pointer;
                    border: none;
                    font-size: 16px;
                    background: transparent;
                    width: 140px;
                    color: #646f79;

                    &:hover {
                        transition: color 200ms ease-in-out;
                        color: #333;
                    }
                }
            `}</style>
        </nav>
    );
};

NewNavbar.displayName = 'NewNavbar';

export default NewNavbar;
