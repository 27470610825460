import React, { useState } from 'react';
import Link from 'next/link';

import InstagramLogo from '../../public/assets/icons/instagram-white.svg';
import FacebookLogo from '../../public/assets/icons/fb-white.svg';
import TiktokLogo from '../../public/assets/icons/tiktok.svg';
import LinkedinLogo from '../../public/assets/icons/linkedin.svg';
import PinterestLogo from '../../public/assets/icons/pinterest.svg';
import YoutubeLogo from '../../public/assets/icons/youtube-white.svg';
import TwitterLogo from '../../public/assets/icons/twitter-white.svg';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { checkValidEmail } from '@/lib/muzology';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import { ErrorMessage } from '@/components/ReusableUI/Form';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { FooterLinkClick, FooterSocialLinkClick } from '@/analytics/events';

const FOOTER_LINKS = [
    // {
    //     title: 'Sign Up My Child',
    //     url: '/checkout'
    // },
    {
        title: 'Sign Up As Educator',
        url: '/signup'
    },
    {
        title: 'Log In',
        url: '/login'
    },

    {
        title: 'About Us',
        url: '/about-us'
    },
    {
        title: 'Research',
        url: '/research'
    },
    {
        title: 'Press',
        url: '/press/'
    },
    {
        title: 'Blog',
        url: '/blog'
    },
    {
        title: 'FAQ',
        url: '/faq'
    },
    {
        title: 'Learn More',
        url: '/learn-more'
    },
    {
        title: 'Contact Us',
        url: '/contact'
    }
];

const SOCIAL_LINKS = [
    {
        title: 'Instagram',
        url: 'https://www.instagram.com/MuzologyEdu/',
        icon: '/assets/icons/instagram-white.svg',
        Logo: InstagramLogo
    },
    {
        title: 'Facebook',
        url: 'https://www.facebook.com/muzologyedu',
        icon: '/assets/icons/fb-white.svg',
        Logo: FacebookLogo
    },

    {
        title: 'Twitter',
        url: 'https://www.twitter.com/MuzologyEdu/',
        Logo: TwitterLogo
    },

    {
        title: 'YouTube',
        url: 'https://www.youtube.com/MuzologyEdu/',
        Logo: YoutubeLogo
    },

    {
        title: 'Pinterest',
        url: 'https://www.pinterest.com/muzology/',
        Logo: PinterestLogo
    },

    {
        title: 'Linkedin',
        url: 'https://www.linkedin.com/company/muzology',
        Logo: LinkedinLogo
    },
    {
        title: 'TikTok',
        url: 'https://www.tiktok.com/@muzology',
        Logo: TiktokLogo
    }
];

export const Footer = ({ hideCTA = false }) => {
    const analytics = useAnalytics();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        register,
        trigger,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {
        setLoading(true);
        axios
            .post('/api/email_signup/', data)
            .then(() => {
                setSubmitted(true);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    return (
        <div className=' px-0 sm:default-px default-py 2xl:px-[50px] '>
            <div className='max-w-[1500px] mx-auto'>
                <div className='flex flex-col lg:flex-row gap-4 justify-between items-center lg:items-start  pt-6'>
                    <div className='w-full max-w-[440px] mt-0 lg:mt-2'>
                        <h5 className='text-slate-700 text-base font-medium my-0 text-center uppercase'>Let's be friends. Follow us on social.</h5>
                        <div className='flex items-center gap-1 sm:gap-4 justify-center mt-6'>
                            {SOCIAL_LINKS.map((link, index) => {
                                return (
                                    <Link key={index} href={link.url}>
                                        <a
                                            className='mx-2 min-w-[20px] text-[#131313] hover:text-[#888888]  active:text-[#dddddd] '
                                            href={link.url}
                                            target='_blank'
                                            rel='noreferrer'
                                            aria-label={link.title}
                                            onClick={() => analytics.track(FooterSocialLinkClick, { link: link.title })}
                                        >
                                            <link.Logo className='footer--main-social-logo' alt={link.title} />
                                            {/*<img className='footer--main-social-logo' src={link.icon} alt={link.title} />*/}
                                        </a>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>

                    {!hideCTA && (
                        <div className='w-full max-w-[420px] px-4 mt-4 lg:-mt-1 px-10 md:px-0 relative'>
                            <LoadingOverlay show={loading} />
                            {submitted ? (
                                <p id='footer-mailing-list-signup-success' className=' text-center mt-0'>
                                    Congrats! You're now a Muzology VIP.
                                </p>
                            ) : (
                                <div id='footer-mailing-list-signup-form' className='w-full'>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className='relative flex flex-col tiny:flex-row gap-2 md:gap-3 border-2 border-[#333] mt-[32px]'>
                                            <div className='w-full'>
                                                <h5 className='block xs2:hidden absolute top-[-70%] left-0 right-0 whitespace-nowrap text-[#4B5563] font-medium text-sm my-1 text-center uppercase'>
                                                    Sign up for our alerts: muzology notes!
                                                </h5>
                                                <div className='relative w-full mr-2 '>
                                                    <h5 className='hidden xs2:block absolute top-[-70%] left-0 right-0 whitespace-nowrap text-[#4B5563] font-medium text-base my-1 text-center uppercase'>
                                                        Sign up for our alerts: muzology notes!
                                                    </h5>
                                                    <input
                                                        placeholder='EMAIL ADDRESS...'
                                                        type='email'
                                                        className='w-full border border-gray-300 rounded-none border-[#E5E7EB] border-solid border-2 flex-grow h-12 px-4'
                                                        {...register('email', { required: true })}
                                                    />
                                                </div>
                                                <ErrorMessage error={errors?.email} requiredText='Please enter your email address.' />
                                            </div>
                                            {/* <p className='xs:hidden text-center text-slate-700 font-light text-[12px] mb-0 uppercase'>
                                                            find out how muzology is changing the world with our breaking news!
                                                        </p> */}
                                            <div className='mx-auto'>
                                                <button
                                                    onClick={() => trigger()}
                                                    className='ml-0 sm:ml-3 uppercase text-base border-none h-[48px] min-w-[100px] max-w-[200px] mx-auto bg-[#1F2365] hover:bg-[#6B7280] text-white font-semibold rounded-md mt-0'
                                                    aria-label='Submit'
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                    <p className=' text-slate-700 font-light text-[12px] uppercase text-center xs2:text-left'>
                                        find out how muzology is changing the world with our breaking news!
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className='bg-[#000] h-[1px] w-[97%] mx-auto my-6' />

                <div className='hidden md:flex flex-col lg:flex-row gap-6'>
                    <div className='w-full'>
                        {/* <ul className='grid list-none grid-cols-2 sm:grid-cols-2 lg:grid-cols-9 gap-[5px]'> */}
                        <ul className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:flex justify-items-center md:justify-items-start xl:items-center footer-xl:flex gap-2 sm:gap-5 list-none justify-start px-0'>
                            {FOOTER_LINKS.map((link, index) => (
                                <li key={index} className='w-full xl:w-[132px] xl:first:w-[200px] text-center'>
                                    <Link key={index} href={link.url ?? '#'} className='no-underline'>
                                        <a
                                            className='whitespace-nowrap text-center w-full font-bold text-sm text-[#131313] hover:text-[#6B7280]  underline-offset-[6px] decoration-[#9CA3AF]'
                                            onClick={() => analytics.track(FooterLinkClick, { link: link.title })}
                                        >
                                            {link.title}
                                        </a>
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='mt-3 sm:mt-5'>
                    <div className='flex flex-col sm:flex-row items-center gap-4'>
                        <p className='font-light font-Inter text-[#6B7280] text-sm text-center sm:text-left my-0 '>
                            Copyright &copy; {new Date().getFullYear()} Muzology, LLC All rights reserved.
                        </p>
                        <div className='flex'>
                            <Link href='/tos/' className='no-underline'>
                                <a className='font-light text-sm text-[#6B7280] hover:text-[#000] underline-offset-[6px] mr-2.5'>Terms of Service</a>
                            </Link>
                            <Link href='/privacy/' className='no-underline'>
                                <a className='font-light text-sm text-[#6B7280] hover:text-[#000] underline-offset-[6px] ml-2.5'>Privacy Policy</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
