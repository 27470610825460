import React from 'react';


export const EmailSignup = 'Email Signup';
export const EmailSignupSuccessful = 'Email Signup Successful';
export const EmailSignupError = 'Email Signup Error';

////////////////////////
// parent flow

// 1 - they enter an email
export const NewParentRegister = 'New_Register_Parent';
export const CheckoutStarted = 'Checkout Started';

// 2 - fill out account setup form
export const NewParentAccount = 'New_Account_Parent';
export const CheckoutSetupComplete = 'Checkout Setup Complete';

// 3 - they complete the purchase
export const NewParentTrial = 'New_Trial_Parent';
export const CheckoutPurchaseComplete = 'Checkout Purchase Complete';

// they cancel the checkout
export const NewParentCancelTrialSignup = 'New_Signup_Cancel_Parent';

////////////////////////
// teacher flow

// 1 - they enter an email
export const NewTeacherRegister = 'New_Register_Teacher';

// 2 - fill out the trial signup form
export const TeacherSignupSuccess = 'Teacher Signup Success';
export const NewTeacherAccount = 'New_Account_Teacher';
export const NewTeacherTrial = 'New_Trial_Teacher';

// 3 - teacher purchase subscription
export const NewPaidTeacher = 'New_Paid_Teacher';
export const NewInitialPaidTeacher = 'New_Initial_Paid_Teacher';


////////////////////////
// checkout flow
export const CheckoutBegins = 'Checkout Begins';
export const CheckoutBeginsTeacher = 'Checkout Begins Teacher';
export const CheckoutPurchaseStarted = 'Checkout Purchase Started';
export const CheckoutPurchaseSubmitting = 'Checkout Purchase Submitting';
export const CheckoutPurchaseCompleteFrontend = 'Checkout Purchase Complete Frontend';
export const CheckoutPurchaseError = 'Checkout Purchase Error';
export const CheckoutCanceled = 'Checkout Canceled';
export const CheckoutResumed = 'Checkout Resumed';
export const CheckoutError = 'Checkout Error';
export const AccountSetupCanceled = 'Account Setup Canceled';
export const PurchaseCanceled = 'Purchase Canceled';


// user type selection flow
export const UserTypeSelectionBegins = 'User Type Selection Begins';
export const UserTypeSelected = 'User Type Selected';
export const UserTypeSubmitError = 'User Type Submit Error';

export const StudentSelected = 'Student Selected';
export const ParentSelected = 'Parent Selected';
export const EducatorSelected = 'Educator Selected';

export const UserTypeSubmitted = 'User Type Submitted';
export const UserTypeSelectionCanceled = 'User Type Selection Canceled';
export const ParentEmailSignup = 'Parent Email Signup';
export const ParentEmailSignupSuccessful = 'Parent Email Signup Successful';

// site clicks
export const FooterSocialLinkClick = 'Footer Social Link Click';
export const FooterLinkClick = 'Footer Link Click';

export const LogoClicked = 'Logo Clicked';
//
export const VideoPlaying = 'Video Playing';
export const VideoPaused = 'Video Paused';
export const VideoSeeked = 'Video Seeked';
export const VideoEnded = 'Video Ended';

// Times Tables Events
// Times Tables Video Events Header
export const TimesTablesVideoPlaying = 'TimesTables Video Playing';
export const TimesTablesVideoPaused = 'TimesTables Video Paused';
export const TimesTablesVideoSeeked = 'TimesTables Video Seeked';
export const TimesTablesVideoEnded = 'TimesTables Video Ended';

// Times Tables Video Events Mashup Section Below
export const TimesTablesVideoMashupPlaying = 'TimesTables Video Playing';
export const TimesTablesVideoMashupPaused = 'TimesTables Video Paused';
export const TimesTablesVideoMashupSeeked = 'TimesTables Video Seeked';
export const TimesTablesVideoMashupEnded = 'TimesTables Video Ended';

export const TimesTablesHeaderCTAButton = 'TimesTables Header CTA Button';
export const TimesTablesPriceSectionCTAButton = 'TimesTables Price Section CTA Button';
export const TimesTablesSection1CTAButton = 'TimesTables Section 1 CTA Button';
export const TimesTablesSection2CTAButton = 'TimesTables Section 2 CTA Button';
export const TimesTablesSection3CTAButton = 'TimesTables Section 3 CTA Button';
export const TimesTablesBottomCTAButton = 'TimesTables Bottom CTA Button';

// Times Tables Checkout Events
export const TimesTablesRegisterParent = 'Times_Tables_Register_Parent';
export const TimesTablesNewPaidParent = 'Times_Tables_New Paid_Parent';
export const TimesTablesPaidParent = 'Times_Tables_Paid_Parent';

export const NewParentPaid = 'New_Paid_Parent';
export const NewParentInitialPaid = 'New_Initial_Paid_Parent';

// export const AnalyticsEvents = {
//
//     EmailSignup,
//     EmailSignupError,
//
//     CheckoutBegins,
//     CheckoutError,
//     CheckoutSetupComplete,
//     CheckoutPurchaseSubmitting,
//     CheckoutPurchaseComplete,
//     CheckoutPurchaseError,
//
//     UserTypeSelectionBegins,
//     UserTypeSelected,
//     UserTypeSubmitError,
//     UserTypeSubmitted,
//     ParentEmailSignup,
//     ParentEmailSignupSuccessful,
//
//     FooterSocialLinkClick,
//     FooterLinkClick,
//     LogoClicked,
//
//     VideoPlaying,
//     VideoPaused,
//     VideoSeeked,
//     VideoEnded
// };

// export default AnalyticsEvents;
